import React from "react";

import Careers from "../images/common/careers.svg";
import Connect from "../images/common/connect.svg";
import About from "../images/common/about.svg";
import Services from "../images/common/services.svg";

import Mtn from "../images/about-us/au_mountain.svg";
import Computer from "../images/about-us/au_computer.svg";
import Code from "../images/about-us/au_code.svg";
import Heart from "../images/about-us/au_heart.svg";
import House from "../images/about-us/au_house.svg";
import Matrix from "../images/about-us/au_matrix.svg";

// company
import Aces from "../images/companies/acesinc-white.svg";
import Aquia from "../images/companies/aquia-white.svg";
import BAH from "../images/companies/BAH-white.svg";
import Barbaricum from "../images/companies/barbaricum-white.svg";
import Bluevoyant from "../images/companies/bluevoyant-white.svg";
import Boeing from "../images/companies/boeing-white.svg";
import F9 from "../images/companies/F9-white.svg";
import Fearless from "../images/companies/fearless-white.svg";
import GrayDigital from "../images/companies/graydigital-white.svg";
import Inovex from "../images/companies/inovex-white.svg";
import Jacobs from "../images/companies/jacobs-white.svg";
import Leidos from "../images/companies/leidos-white.svg";
import Lockheed from "../images/companies/lockheed-white.svg";
import Mantech from "../images/companies/mantech-white.svg";
import Maxar from "../images/companies/maxar-white.svg";
import NCyber from "../images/companies/ncyber-white.svg";
import NVidia from "../images/companies/nvida-white.svg";
import Oddball from "../images/companies/oddball-white.svg";
import Omni from "../images/companies/omnifederal-white.svg";
import Onyxpoint from "../images/companies/onyxpoint-white.svg";
import Oteemo from "../images/companies/oteemo-white.svg";
import Parsons from "../images/companies/parsons-white.svg";
import Raytheon from "../images/companies/raytheon-white.svg";
import Rebellion from "../images/companies/rebellion-white.svg";
import Revacomm from "../images/companies/revacomm-white.svg";
import Rise8 from "../images/companies/rise8-white.svg";
import TexasAM from "../images/companies/texasA_m-white.svg";
import Visionist from "../images/companies/visionist-white.svg";
import Vivsoft from "../images/companies/vivsoft-white.svg";
import VMWare from "../images/companies/vmware-white.svg";
import WWT from "../images/companies/WWT-white.svg";

export const determineIcon = (iconName) => {
  switch (iconName) {
    case "au_mountain":
      return <Mtn className="h-8 w-8 xl:h-10 xl:w-10 2xl:w-12 2xl:h-12" />;
    case "au_house":
      return <House className="h-8 w-8 xl:h-10 xl:w-10 2xl:w-12 2xl:h-12" />;
    case "au_heart":
      return <Heart className="h-8 w-8 xl:h-10 xl:w-10 2xl:w-12 2xl:h-12" />;
    case "au_computer":
      return <Computer className="h-8 w-8 xl:h-10 xl:w-10 2xl:w-12 2xl:h-12" />;
    case "au_code":
      return <Code className="h-8 w-8 xl:h-10 xl:w-10 2xl:w-12 2xl:h-12" />;
    case "au_matrix":
      return <Matrix className="h-8 w-8 xl:h-10 xl:w-10 2xl:w-12 2xl:h-12" />;
    case "careers":
      return <Careers className="w-10 h-10 mb-3 2xl:w-12 2xl:h-12" />;
    case "connect":
      return <Connect className="w-10 h-10 mb-3 2xl:w-12 2xl:h-12" />;
    case "about":
      return <About className="w-10 h-10 mb-3 2xl:w-12 2xl:h-12" />;
    case "services":
      return <Services className="w-10 h-10 mb-3 2xl:w-12 2xl:h-12" />;
    default:
      return "no icon selected";
  }
};

export const determineCompany = (company) => {
  switch (company) {
    case "aces":
      return <Aces className="w-32 h-auto" />;
    case "aquia":
      return <Aquia className="w-32 h-auto" />;
    case "bah":
      return <BAH className="w-32 h-auto" />;
    case "barbaricum":
      return <Barbaricum className="w-32 h-auto" />;
    case "bluevoyant":
      return <Bluevoyant className="w-32 h-auto" />;
    case "boeing":
      return <Boeing className="w-32 h-auto" />;
    case "f9":
      return <F9 className="w-16 h-auto" />;
    case "fearless":
      return <Fearless className="w-32 h-auto" />;
    case "graydigital":
      return <GrayDigital className="w-32 h-auto" />;
    case "inovex":
      return <Inovex className="w-32 h-auto" />;
    case "jacobs":
      return <Jacobs className="w-32 h-auto" />;
    case "leidos":
      return <Leidos className="w-32 h-auto" />;
    case "lockheed":
      return <Lockheed className="w-32 h-auto" />;
    case "mantech":
      return <Mantech className="w-32 h-auto" />;
    case "maxar":
      return <Maxar className="w-32 h-auto" />;
    case "ncyber":
      return <NCyber className="w-32 h-auto" />;
    case "nvidia":
      return <NVidia className="w-32 h-auto" />;
    case "oddball":
      return <Oddball className="w-32 h-auto" />;
    case "omni":
      return <Omni className="w-32 h-auto" />;
    case "onyxpoint":
      return <Onyxpoint className="w-32 h-auto" />;
    case "oteemo":
      return <Oteemo className="w-32 h-auto" />;
    case "parsons":
      return <Parsons className="w-32 h-auto" />;
    case "raytheon":
      return <Raytheon className="w-32 h-auto" />;
    case "rebellion":
      return <Rebellion className="w-32 h-auto" />;
    case "revacomm":
      return <Revacomm className="w-32 h-auto" />;
    case "rise8":
      return <Rise8 className="w-32 h-auto" />;
    case "texasam":
      return <TexasAM className="w-16 h-auto" />;
    case "visionist":
      return <Visionist className="w-32 h-auto" />;
    case "vivsoft":
      return <Vivsoft className="w-32 h-auto" />;
    case "vmware":
      return <VMWare className="w-32 h-auto" />;
    case "wwt":
      return <WWT className="w-32 h-auto" />;
    default:
      return "company not in list";
  }
};

export default {
  determineIcon,
  determineCompany,
};
