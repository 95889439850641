import React from "react";
import PropTypes from "prop-types";

import Header from "./header";
import Footer from "./footer";
import { ThemeContext } from "../../../provider";
import BackToTop from "./backtotop";

export default function Layout({ children }) {
  return (
    <ThemeContext.Consumer>
      {(context) => (
        <div
          id="bg"
          className={`${
            context.isDark ? `theme-dark` : `theme-light`
          } flex flex-col min-h-screen font-sans bg-ci-blue-700 relative`}
        >
          <Header />
          <main>{children}</main>
          <BackToTop />
          <Footer />
        </div>
      )}
    </ThemeContext.Consumer>
  );
}
Layout.propTypes = {
  children: PropTypes.node.isRequired,
};
