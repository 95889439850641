import { Link } from "gatsby";
import React from "react";
import FB from '../../images/common/fb.svg';
import In from '../../images/common/linkedin.svg';

import MLink from '../common/link';
import { about, careers, connect } from '../../../routes';

const Footer = () => (
  <footer className="bg-black relative">
    <nav className="p-4  text-sm container lg:mx-auto lg:max-w-5xl">
      <div className="flex flex-col justify-start items-start lg:flex-row lg:items-start lg:space-between">
        <section className="mt-2 lg:mt-0 lg:pt-8 lg:pb-20 lg:w-1/4">
          <Link to="/about-us" className="text-white text-center font-semibold text-2xl">About</Link>
          <ul className="footer-ul flex flex-col items-start h-full">
            {about.map(({name, route}, index) => (
              <li className="text-white text-lg my-1" key={route}><MLink to={route} off={index === 0 ? -96 : 0}>{name}</MLink></li>))}
          </ul>
        </section>
        <section className="mt-8 lg:mt-0 lg:pt-8 lg:pb-20 lg:w-1/4">
          <Link to="/services" className="text-white text-center font-semibold text-2xl">Services</Link>
          <ul className="footer-ul flex flex-col items-start h-full">
            <li className="text-white text-lg my-1"><MLink to={`/services/#what-we-offer`}>What We Offer</MLink></li>
          </ul>
        </section>
        <section className="mt-8 lg:mt-0 lg:pt-8 lg:pb-20 lg:w-1/4">
          <Link to="/careers" className="text-white text-center font-semibold text-2xl">Careers</Link>
          <ul className="footer-ul flex flex-col items-start h-full">
            {careers.map(({name, route}) => (
            <li className="text-white text-lg my-1" key={route}><MLink to={route}>{name}</MLink></li>))}
          </ul>
        </section>
        <section className="mt-8 lg:mt-0 lg:pt-8 lg:pb-20 lg:w-1/4">
          <Link to="/contact-us" className="text-white text-center font-semibold text-2xl">Connect</Link>
          <ul className="footer-ul flex flex-col items-start h-full">
            {connect.map(({name, route}) => (
              <li className="text-white text-lg my-1" key={route}><a href={`${route}`}>{name}</a></li>))}
            <li className="text-white text-lg my-1">
              <a href="https://clarity-hosted-logo.s3.amazonaws.com/Clarity+Draft+Website+Privacy+Policy.pdf" rel="noopenner noreferrer" target="_blank">Privacy Policy</a>
            </li>  
          </ul>
        </section>
        <section className="mt-8 w-auto absolute bottom-0 right-0 sm:hidden">
          <ul className="sm:hidden flex justify-between items-center">
            <li className="flex sm:p-2 sm:block" style={{width: '64px', height: '64px'}}>
              <a className="w-full h-full p-4" href="https://facebook.com/clarityinnovations" rel="noopenner noreferrer" target="_blank">
                <FB className="w-6 h-6" /><span className="sr-only">Clarity Innovations Facebook</span>
              </a>
            </li>
            <li className="flex sm:p-2 sm:block" style={{width: '64px', height: '64px'}}>
              <a className="w-full h-full p-4" href="https://linkedin.com/company/clarityinnovations" rel="noopenner noreferrer" target="_blank">
                <In className="w-6 h-6" /><span className="sr-only">Clarity Innovations LinkedIn</span>
              </a>
            </li>
          </ul>
        </section>
      </div>
    </nav>
  </footer>
);

export default Footer;
