import React from 'react';
import PropTypes from 'prop-types';
import scrollTo from 'scroll-to-element';
import { Link } from 'gatsby';
import { navigate } from '@reach/router';

const MLink = ({ children, cb, to, off, ...rest }) => {
  const handleNavigation = async (e, route, cb) => {
    e.preventDefault();
    if(cb) {
      cb();
    }
    const [url, hash] = route.split('#');
    if(typeof window !== 'undefined' && hash) {
      await navigate(`${url}`);
      setTimeout(() => {
        scrollTo(`#${hash}`, {
          behavior: 'smooth',
          offset: off ? off : 0
        })
      }, 350)
    } else {
      navigate(`${route}`);
    }
  }
  return (
    <Link
      to={to}
      onClick={e => handleNavigation(e, to, cb)}
      {...rest}>
      {children}
    </Link>
  )
}

export default MLink;

MLink.propTypes = {
  children: PropTypes.node,
  to: PropTypes.string.isRequired,
  cb: PropTypes.func,
  off: PropTypes.number
}