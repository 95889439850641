export const connect = [
  {
    name: "Contact Us",
    route: "/contact-us#contact-us",
  },
];

export const careers = [
  {
    name: "Our Benefits",
    route: "/careers/#our-benefits",
  },
  {
    name: "Career Opportunities",
    route: "/careers/openings#openings",
  },
];

export const services = [
  {
    name: "Digital Services",
    route: "/services/#digital-services",
  },
  {
    name: "User Experience & Design",
    route: "/services/#ux-design",
  },
  {
    name: "Mobile & Web",
    route: "/services/#mobile-and-web",
  },
  {
    name: "Cloud & Platform",
    route: "/services/#cloud-and-platform",
  },
  {
    name: "DataOps",
    route: "/services/#data-ops",
  },
  {
    name: "Full Stack Development",
    route: "/services/#full-stack-development",
  },
  {
    name: "Data Sciences & Analytics",
    route: "/services/#data-science-and-analytics",
  },
];

export const about = [
  {
    name: "Our Story",
    route: "/about-us/#story",
  },
  {
    name: "Our Mission",
    route: "/about-us/#mission",
  },
  {
    name: "Our Leadership",
    route: "/about-us/#leadership",
  },
];

export const routes = [
  {
    route: `/about-us`,
    title: `About`,
    sublinks: about,
    color: "#00518a",
  },
  {
    route: `/services`,
    title: `Services`,
    sublinks: services,
    color: "#1d6aaf",
  },
  {
    route: `/careers`,
    title: `Careers`,
    sublinks: careers,
    color: "#108cc6",
  },
  {
    route: `/contact-us`,
    title: `Connect`,
    sublinks: connect,
    color: "#5bb9d5",
  },
];

export default {
  routes,
  about,
  services,
  careers,
  connect,
};
