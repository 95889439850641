import { graphql, useStaticQuery } from "gatsby";

const useImage = () => {
  const data = useStaticQuery(graphql`
    query {
      logoLight: file(relativePath: { eq: "common/c-logo-light.png" }) {
        sharp: childImageSharp {
          fixed(height: 88) {
            ...GatsbyImageSharpFixed_tracedSVG
          }
        }
      }
      logoDark: file(relativePath: { eq: "common/c-logo-dark.png" }) {
        sharp: childImageSharp {
          fixed(height: 88) {
            ...GatsbyImageSharpFixed_tracedSVG
          }
        }
      }
      testimonial: file(relativePath: { eq: "home/testimonial.jpg" }) {
        sharp: childImageSharp {
          fluid(quality: 90) {
            src
          }
        }
      }
      servicesQuoteSM: file(
        relativePath: { eq: "services/servicesQuote-sm.jpg" }
      ) {
        sharp: childImageSharp {
          fluid(quality: 85, maxWidth: 500) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      servicesQuoteMD: file(
        relativePath: { eq: "services/servicesQuote-md.jpg" }
      ) {
        sharp: childImageSharp {
          fluid(quality: 90, maxWidth: 768) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      servicesQuoteLG: file(
        relativePath: { eq: "services/servicesQuote-lg.jpg" }
      ) {
        sharp: childImageSharp {
          fluid(quality: 95, maxWidth: 1200) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      servicesQuoteXL: file(
        relativePath: { eq: "services/servicesQuote-xl.jpg" }
      ) {
        sharp: childImageSharp {
          fluid(quality: 100, maxWidth: 2550) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      servicesHeroSM: file(
        relativePath: { eq: "services/servicesHero-sm.jpg" }
      ) {
        sharp: childImageSharp {
          fluid(quality: 85, maxWidth: 500) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      servicesHeroMD: file(
        relativePath: { eq: "services/servicesHero-md.jpg" }
      ) {
        sharp: childImageSharp {
          fluid(quality: 90, maxWidth: 768) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      servicesHeroLG: file(
        relativePath: { eq: "services/servicesHero-lg.jpg" }
      ) {
        sharp: childImageSharp {
          fluid(quality: 95, maxWidth: 1200) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      servicesHeroXL: file(
        relativePath: { eq: "services/servicesHero-xl.jpg" }
      ) {
        sharp: childImageSharp {
          fluid(quality: 100, maxWidth: 2550) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      aboutHeroSM: file(relativePath: { eq: "about-us/aboutHero-sm.jpg" }) {
        sharp: childImageSharp {
          fluid(quality: 85, maxWidth: 500) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      aboutHeroMD: file(relativePath: { eq: "about-us/aboutHero-md.jpg" }) {
        sharp: childImageSharp {
          fluid(quality: 90, maxWidth: 768) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      aboutHeroLG: file(relativePath: { eq: "about-us/aboutHero-lg.jpg" }) {
        sharp: childImageSharp {
          fluid(quality: 95, maxWidth: 1200) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      aboutHeroXL: file(relativePath: { eq: "about-us/aboutHero-xl.jpg" }) {
        sharp: childImageSharp {
          fluid(quality: 100, maxWidth: 2550) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      creedSM: file(relativePath: { eq: "about-us/creed-sm.jpg" }) {
        sharp: childImageSharp {
          fluid(quality: 85, maxWidth: 500) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      creedMD: file(relativePath: { eq: "about-us/creed-md.jpg" }) {
        sharp: childImageSharp {
          fluid(quality: 90, maxWidth: 768) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      creedLG: file(relativePath: { eq: "about-us/creed-lg.jpg" }) {
        sharp: childImageSharp {
          fluid(quality: 95, maxWidth: 1200) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      creedXL: file(relativePath: { eq: "about-us/creed-xl.jpg" }) {
        sharp: childImageSharp {
          fluid(quality: 100, maxWidth: 2550) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      ceo: file(relativePath: { eq: "ceo.jpg" }) {
        sharp: childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      heliSM: file(relativePath: { eq: "home/helicopter-sm.jpg" }) {
        sharp: childImageSharp {
          fluid(quality: 85, maxWidth: 500) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      heliMD: file(relativePath: { eq: "home/helicopter-md.jpg" }) {
        sharp: childImageSharp {
          fluid(quality: 90, maxWidth: 768) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      heliLG: file(relativePath: { eq: "home/helicopter-lg.jpg" }) {
        sharp: childImageSharp {
          fluid(quality: 95, maxWidth: 1200) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      heliXL: file(relativePath: { eq: "home/helicopter-xl.jpg" }) {
        sharp: childImageSharp {
          fluid(quality: 100, maxWidth: 2550) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      aboutQuoteSM: file(relativePath: { eq: "about-us/au_ceo-sm.jpg" }) {
        sharp: childImageSharp {
          fluid(quality: 85, maxWidth: 500) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      aboutQuoteMD: file(relativePath: { eq: "about-us/au_ceo-md.jpg" }) {
        sharp: childImageSharp {
          fluid(quality: 90, maxWidth: 768) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      aboutQuoteLG: file(relativePath: { eq: "about-us/au_ceo-lg.jpg" }) {
        sharp: childImageSharp {
          fluid(quality: 95, maxWidth: 1200) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      aboutQuoteXL: file(relativePath: { eq: "about-us/au_ceo-xl.jpg" }) {
        sharp: childImageSharp {
          fluid(quality: 100, maxWidth: 2550) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      wes: file(relativePath: { eq: "about-us/Wes.png" }) {
        sharp: childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      dustin: file(relativePath: { eq: "about-us/Dustin.png" }) {
        sharp: childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      kuk: file(relativePath: { eq: "about-us/KUK.png" }) {
        sharp: childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      careersSM: file(relativePath: { eq: "careers/careers-sm.jpg" }) {
        sharp: childImageSharp {
          fluid(quality: 85, maxWidth: 500) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      careersMD: file(relativePath: { eq: "careers/careers-md.jpg" }) {
        sharp: childImageSharp {
          fluid(quality: 90, maxWidth: 768) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      careersLG: file(relativePath: { eq: "careers/careers-lg.jpg" }) {
        sharp: childImageSharp {
          fluid(quality: 95, maxWidth: 1200) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      careersXL: file(relativePath: { eq: "careers/careers-xl.jpg" }) {
        sharp: childImageSharp {
          fluid(quality: 100, maxWidth: 2550) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      bgContactLaptop: file(
        relativePath: { eq: "contact-us/contact-us-bg.jpg" }
      ) {
        sharp: childImageSharp {
          fluid(quality: 90, maxWidth: 1440) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      bgContactDesktop: file(
        relativePath: { eq: "contact-us/contact-us-bg@2x.jpg" }
      ) {
        sharp: childImageSharp {
          fluid(quality: 90, maxWidth: 2550) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      officeMDLaptop: file(relativePath: { eq: "contact-us/office-md.jpg" }) {
        sharp: childImageSharp {
          fluid(quality: 100, maxWidth: 1440, grayscale: true) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      officeMDDesktop: file(
        relativePath: { eq: "contact-us/office-md@2x.jpg" }
      ) {
        sharp: childImageSharp {
          fluid(quality: 100, grayscale: true) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      officeALLaptop: file(relativePath: { eq: "contact-us/office-al.jpg" }) {
        sharp: childImageSharp {
          fluid(quality: 100, maxWidth: 1440, grayscale: true) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      officeALDesktop: file(
        relativePath: { eq: "contact-us/office-al@2x.jpg" }
      ) {
        sharp: childImageSharp {
          fluid(quality: 100, grayscale: true) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      officeMALaptop: file(relativePath: { eq: "contact-us/office-ma.jpg" }) {
        sharp: childImageSharp {
          fluid(quality: 100, maxWidth: 1440, grayscale: true) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      officeMADesktop: file(
        relativePath: { eq: "contact-us/office-ma@2x.jpg" }
      ) {
        sharp: childImageSharp {
          fluid(quality: 100, grayscale: true) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      videoPoster: file(relativePath: { eq: "home/video-poster.png" }) {
        sharp: childImageSharp {
          fluid(quality: 100, maxWidth: 1440) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      best2020: file(relativePath: { eq: "home/best-2020.png" }) {
        sharp: childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      best2021: file(relativePath: { eq: "home/best-2021.png" }) {
        sharp: childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      designAward: file(relativePath: { eq: "home/design.png" }) {
        sharp: childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      mdaasLaptop: file(relativePath: { eq: "devsecops/mdaas-hero.png" }) {
        sharp: childImageSharp {
          fluid(quality: 100, maxWidth: 1440, grayscale: true) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      mdaasDesktop: file(
        relativePath: { eq: "devsecops/mdaas-hero@2x.png" }
      ) {
        sharp: childImageSharp {
          fluid(quality: 100, grayscale: true) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  return data;
};

export default useImage;
