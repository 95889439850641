import React from 'react';
import BTT from '../../images/common/top.svg';

const handleBackToTop = () => {
  if(typeof window !== 'undefined') {
    window.scroll({
      behavior: 'smooth',
      top: 0, left: 0
    })
  }
}

const Top = () => (
  <button className="back-to-top" onClick={() => handleBackToTop()}>
    <span className="sr-only">Back to Top</span>
    <BTT className="back-to-top-svg"/>
  </button>
)

export default Top;