import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { animated, config, useSpring, useChain, useTransition } from 'react-spring';
import { Link } from "gatsby";
import FocusTrap from 'focus-trap-react';

import MLink from '../common/link';
import { routes } from "../../../routes";
import { determineIcon } from '../../utils/icons';

const SideNav = ({open, onClick}) => {
  const springRef = useRef();
  const { x } = useSpring({
    ref: springRef,
    config: config.default,
    from: { x: -100 },
    x: open ? 0 : -100
  })
  const transRef = useRef();
  const transitions = useTransition(open ? routes : [], item => item.route, {
    ref: transRef, unique: true, reset: true,
    trail: 800 / routes.length,
    from: { opacity: 0, transform: 'scale(0)', width: '0%'},
    enter: { opacity: 1, transform: 'scale(1)', width: '100%'},
    leave: { opacity: 0, transform: 'scale(0)', width: '0%'}
  });

  const ftOpts = {
    onDeactivates: onClick, escapeDeactivates: true,
    clickOutsideDeactivates: true, preventScroll: true
  }

  const handleKeyup = e => {
    return e.which === 27 || e.code === 'Escape' || e.key === 'Escape'
      ? onClick() : null;
  }

  useChain(open ? [springRef, transRef] : [springRef, transRef], [0, open ? 0.28 : 0.8]);

  return (
    <FocusTrap active={open} focusTrapOptions={ftOpts}>
      <animated.div id="side-nav" onKeyUp={handleKeyup}
        style={{
          transform: x.interpolate((x) => `translate3d(${x}%, 0, 0)`),
          marginTop: '96px', zIndex: 50
        }}>
          <button onClick={onClick}
            style={{
              position: 'absolute', tabIndex: -1,
              width: '48px', height: '48px',
              top: '-70px', left: '19px',
              zIndex: `${open ? '1' : '-1'}`,
              display: `${open ? 'block' : 'hidden'}`
            }}>
            <span className="sr-only">Close navigation menu</span>
          </button>
        <div className="lg:w-full">
          <nav className="nav-grid grid grid-cols-1 grid-rows-1 fixed lg:mt-0 overflow-hidden fixed max-w-full lg:grid-cols-4 gap-0 lg:ml-0 z-50">
            { transitions.map(({ key, item: { route, title, color, sublinks}, props }) => (
                <animated.div key={key}
                  className={`nav-blocks col-auto text-white md:flex md:flex-col md:justify-center md:items-center relative`}
                  style={{ backgroundColor: color, ...props }}>
                  <div className="p-4 flex md:p-6 md:flex-col md:items-center md:justify-center">
                    {determineIcon(title.toLowerCase())}
                    <Link to={`${route}`} onClick={onClick} className="pl-4 w-full tracking-widest font-bold uppercase text-2xl md:text-3xl md:pl-0 md:w-auto mt-2 2xl:text-4xl">{title}</Link>
                    <ul className="hidden lg:flex flex-col" id="header-ul">
                      {sublinks.map(({name, route}) => (
                        <animated.li key={name} className="text-left py-2 md:text-center md:text-lg 2xl:text-xl">
                          <MLink to={route} cb={onClick}>{name}</MLink>
                        </animated.li>
                      ))}
                    </ul>
                  </div>
                </animated.div>
              ))}
          </nav>
        </div>
      </animated.div>
    </FocusTrap>
  )
}

export default SideNav;

SideNav.propTypes = {
  open: PropTypes.bool,
  onClick: PropTypes.func
}